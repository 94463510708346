import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextMediaSplit = makeShortcode("TextMediaSplit");
const ImageBar = makeShortcode("ImageBar");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextMediaSplit imagePath="portfolio/exhibition/exhibition-main.jpg" imageAlt="Exhibition entrance" mdxType="TextMediaSplit">
      <h1>{`Niki de Saint Phalle Exhibition`}</h1>
      <p>{`Saint Phalle was arguably one of the most avant-garde sculptors of our time. Her dedication to showcasing feminism and the female form cements her status in the modern world which continues to inspire and delight. `}</p>
      <p>{`This imaginary exhibition housed at the Tate Modern takes inspiration from, and focusses on, Saint Phalle’s world-renowned Nana sculptures.`}</p>
      <p>{`As part of this visual system I created a colour scheme, logotype, visual assets, promotional materials and informative ephemera, merchandise and a way-finding system. A selection of which can be found below.`}</p>
    </TextMediaSplit>
    <ImageBar imagesDirectory="portfolio/exhibition/lightbox" mdxType="ImageBar" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      